import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, Optional } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { FilesDownloadComponent } from '../../download/component/files-download/files-download.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-files-download-dialog',
  templateUrl: './files-download-dialog.component.html',
  styleUrls: ['./files-download-dialog.component.scss'],
  standalone: true,
  imports: [MatIcon, MatDialogClose, MatDialogContent, FilesDownloadComponent],
})
export class FilesDownloadDialogComponent {
  public data: { files: Array<string>; title: string };

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: { files: Array<string>; title: string },
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) private bottomData: { files: Array<string>; title: string },

    @Optional() private dialogRef: MatDialogRef<FilesDownloadDialogComponent>,
    @Optional() private bottomRef: MatBottomSheetRef<FilesDownloadDialogComponent>
  ) {
    this.data = dialogData || bottomData;
  }

  close(): void {
    this.dialogRef?.close();
    this.bottomRef?.dismiss();
  }
}
