import { gEnvironment } from '../../../environments/global_environment';
import { FilesDownloadDialogComponent } from '../../shared/dialogs/files-download-dialog/files-download-dialog.component';
import { ProfileService } from '../profile-service/profile.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewLiveRecordingDialogComponent } from '../../shared/dialogs/view-live-recording-dialog/view-live-recording-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { LiveMaterialEnum, LiveUserStatus, Meeting, MindMapInfo, TranscriptionInfo } from '../interfaces/meeting';
import { UserService } from '../user-service/user.service';
import { UserBookedLive } from '../interfaces/user';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { apiUrl, createCustomHeader } from '../util/http.util';
import { Flashcard } from '../interfaces/flashcard';
import { JoinOptions } from '@zoom/meetingsdk/embedded';
import { FutDialogService } from '../dialog-service/fut-dialog.service';

interface ZoomSdkJoinOptions {
  signature: string;
  meeting_id: string | number;
  username: string;
  apiKey: string;
  useremail: string;
  password: string;
  customerKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class LiveService {
  frame_location = 'assets/zoom-frame.html';

  readonly NEGATIVE_MILLS_TO_SHOW_LIVE = 10 * 60 * 1000;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private futDialog: FutDialogService,
    private profileService: ProfileService
  ) {}

  // Observable
  public getAll(): Observable<Array<Meeting>> {
    return this.userService.getLabel().pipe(
      switchMap(labels =>
        forkJoin([
          this.http.get<Array<Meeting>>(
            apiUrl + '/zoom/',
            createCustomHeader({
              custom_params: { Labels: labels },
            })
          ),
          this.profileService.getCategories(),
        ])
      ),
      map(([meetings, categories]) => {
        const cat1 = Array.from(new Set(categories.map(c => c.split('.').splice(0, 1).join('.'))));
        const cat2 = Array.from(new Set(categories.map(c => c.split('.').splice(0, 2).join('.'))));
        const cat3 = categories;
        meetings = meetings.filter(meetings => meetings.content.categories.some(c => cat1.includes(c) || cat2.includes(c) || cat3.includes(c)));

        // // Filtro per live che sono ondemand e sono impostate nel futuro
        // meetings = meetings.filter(meeting => {
        //   return !meeting.content.exclude_zoom || meeting.content.start_time < this.utils.getTime();
        // })

        // meetings.forEach(meeting => { meeting.content.start_time *= 1000 });
        return meetings.sort((a, b) => (b.content.start_time > a.content.start_time ? 1 : -1));
      })
    );
  }

  public get(live_id: string): Observable<Meeting> {
    return this.http.get<Meeting>(apiUrl + `/zoom/live/${live_id}`).pipe(
      map(meeting => {
        // delete meeting.content.recording;
        return meeting;
      })
    );
  }

  public getTranscription(instanceId: string): Observable<TranscriptionInfo> {
    const url = `${apiUrl}/zoom/transcription`;
    return this.http
      .get<TranscriptionInfo>(
        url,
        createCustomHeader({
          target_resource: instanceId,
        })
      )
      .pipe(catchError(() => of({ transcription: '', transcription_time: [] } as TranscriptionInfo)));
  }

  public getMindMap(instanceId: string): Observable<MindMapInfo> {
    const url = `${apiUrl}/zoom/mind_map?live_uuid=${instanceId}`;
    return this.http.get<MindMapInfo>(url);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public downloadSlide(download: string, live?: Meeting) {
    window.open(download, '_blank');
  }

  public downloadSlides(live: Meeting) {
    live.content.files = (live.content.files || []).filter(d => d);

    if ((live.content.files || []).length == 0) {
      this.toastrService.warning('Le slide per questa registrazioni non sono ancora state pubblicate', '', {
        closeButton: true,
        progressBar: true,
        positionClass: 'toast-bottom-right',
      });
      return;
    }

    if (live.content.files.length == 1) {
      this.downloadSlide(live.content.files[0], live);
      return;
    }

    this.futDialog.openDialogOrBottomSheet(FilesDownloadDialogComponent, {
      dimension: 'lg',
      matConf: { data: { files: live.content.files, title: live.content.title } },
    });

    // let index = 0;
    // for(let download of live.content.download) {
    //   if(!download.url) continue;
    //   fetch(download.url)
    //   .then(res => {
    //     return res.blob();
    //   })
    //   .then(blob => {
    //       index++;
    //       const href = window.URL.createObjectURL(blob);
    //       const a = document.createElement("a");
    //       const ext = download.url.split(".");
    //       a.download = live.content.title + `_allegato_${index}.` + ext[ext.length - 1];
    //       a.href = href;
    //       document.body.appendChild(a);
    //       a.click();
    //       a.href = "";
    //       document.body.removeChild(a);
    //     })
    //     .catch(err => {
    //       this.toastrService.error('Si è verificato un errore, riprovare!', '', {closeButton: true, progressBar: true, positionClass: 'toast-bottom-right'});
    //     });
    // }
  }

  public openLiveFrameDialog(live: Meeting) {
    this.dialog.open(ViewLiveRecordingDialogComponent, {
      data: { live },
      width: '90vw',
      height: '70vh',
      panelClass: 'fut-mat-dialog-transparent',
    });
  }

  public getUserBookedLives(): Observable<Array<UserBookedLive>> {
    return of([]);
    // this.http.get<Array<UserBookedLive>>(apiUrl + "/users/booked_lives").subscribe(resolve, reject);;
  }

  public getNextLiveOnAir(): Observable<Meeting | undefined> {
    return forkJoin([this.getAll(), this.getUserBookedLives()]).pipe(
      map(([lives, bookedLives]) => {
        let live_found: Meeting | undefined = undefined;
        for (const live of lives) {
          // Skip if live is not started
          if (live.content.status.status == 'ended') continue;
          // Getting time starting live
          // Use this if live is started
          if (live.content.status.status == 'started') {
            live_found = live;
            break;
          }
          const live_start_time = new Date(+live.content.start_time);
          const now = moment();
          // Skip if live start time is in the past
          if (live_start_time.getTime() < new Date().getTime() - this.NEGATIVE_MILLS_TO_SHOW_LIVE) continue;
          // Skip if live is not in 24 hours
          // if (now.add(1, 'days') < moment(live_start_time)) continue;

          // Se la live è su prenotazione e l'utente non ha prenotato la live, la salto
          if (live.content.need_registration && !bookedLives.some(b => b.live_id == live.instance_id)) continue;

          // Skip if live found and is nearer
          if (live_found && moment(live_found.content.start_time).diff(now) < moment(live.content.start_time).diff(now)) continue;
          // Save the live
          live_found = live;
        }
        return live_found;
      })
    );
  }

  public getSignature(meeting_id: string): Observable<string> {
    return this.http
      .get<{
        signature: string;
      }>(apiUrl + '/zoom/signature/' + meeting_id)
      .pipe(map(signature => signature.signature));
  }

  // public startMeeting(meeting: Meeting): Observable<string> {
  //   return this.userService.getFuturaUser().pipe(
  //     map(
  //       user => this.getSignature(meeting.content.id),
  //       map(signature => {
  //         const email = user.content.email.split('+').join('');
  //         const username = `${user.content.name.trim()} ${user.content.surname.trim()}`;
  //         const data: { [key: string]: string | undefined } = {
  //           signature,
  //           meeting_id: meeting.content.id,
  //           username,
  //           apiKey: gEnvironment.zoom_key,
  //           useremail: email || `${username}@wearefutura.com`,
  //           password: meeting.content.password,
  //           customerKey: user.instance_id,
  //         };

  //         const query = Object.keys(data)
  //           .filter(key => data[key])
  //           .map(key => key + '=' + data[key])
  //           .join('&');

  //         return `${this.frame_location}?${query}`;
  //       })
  //     )
  //   );
  // }

  public startMeeting(meeting: Meeting, fullscreen?: boolean): Observable<string> {
    return this.meetingStartData(meeting).pipe(
      map((_data: object) => {
        const data = _data as { [key: string]: string | number };
        data['fullscreen'] = fullscreen ? 1 : 0;
        const query = Object.keys(data)
          .filter((key: string) => data[key])
          .map(key => key + '=' + data[key])
          .join('&');

        return `${this.frame_location}?${query}`;
      })
    );
  }

  public meetingStartData(meeting: Meeting): Observable<ZoomSdkJoinOptions> {
    return forkJoin([this.userService.getFuturaUser(), this.getSignature(meeting.content.id)]).pipe(
      map(([user, signature]) => {
        const email = user.content.email.split('+').join('');
        const username = `${user.content.name.trim()} ${user.content.surname.trim()}`;
        const data = {
          signature,
          meeting_id: meeting.content.id,
          username,
          apiKey: gEnvironment.zoom_key,
          useremail: email || `${username}@wearefutura.com`,
          password: '0000',
          customerKey: user.instance_id,
        };

        return data;
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public book(live: string): Observable<void> {
    return of();
    // this.http.put<void>(environment.be_url + "/zoom/book", {}, createCustomHeader({token, target_resource: live})).subscribe(resolve, reject);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getBooked(live: string): Observable<Array<{ booked_at: string; live_id: string; user: string }>> {
    return of([]);
    //  this.http.get<Array<{booked_at: string, live_id: string, user: string}>>(environment.be_url + "/zoom/book", createCustomHeader({token, target_resource: live})).subscribe(resolve, reject);
  }

  public getFlashcards(videoId: string): Observable<Array<{ deck_uuid: string; flashcards: Flashcard[] }>> {
    return this.http.get<Array<{ deck_uuid: string; flashcards: Flashcard[] }>>(apiUrl + '/flashcards/live', createCustomHeader({ target_resource: videoId }));
  }

  public teacherInfoGet(email: string): Observable<{ name: string; surname: string }> {
    return this.http
      .get<{ name: string; surname: string }>(apiUrl + '/zoom/teacher_info', createCustomHeader({ target_resource: email }))
      .pipe(catchError(() => of({ name: 'Nessun', surname: 'Docente' })));
  }

  public setSeen(liveId: string, material: LiveMaterialEnum, seen: boolean, data: object = {}): Observable<void> {
    return this.http.post<void>(apiUrl + `/zoom/live/user/${liveId}/${material}`, { data, seen });
  }

  public setLiveAsSeen(liveId: string, seen: boolean): Observable<void> {
    return this.http.post<void>(apiUrl + `/zoom/live/user/${liveId}/seen`, { seen });
  }

  public getUserStatus(liveId: string): Observable<LiveUserStatus | null> {
    return this.http.get<LiveUserStatus | null>(apiUrl + `/zoom/live/user/${liveId}`);
  }

  public setLiveTime(liveId: string, time: number, totalTime: number): Observable<void> {
    return this.http.post<void>(apiUrl + `/zoom/live/user/${liveId}/time`, { time, total_time: totalTime });
  }

  public getUserStatusLives(liveIds: string[] = []): Observable<LiveUserStatus[]> {
    return this.http.post<LiveUserStatus[]>(apiUrl + '/zoom/live/user', { live_ids: liveIds });
  }
}
