import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { forkJoin, of, Subscription } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map, timeout } from 'rxjs/operators';
import { DownloadService } from '../../../../core/download-service/download.service';
import { SourceType } from '../../../video/mode/video.model';
import { UtilsService } from '../../../../core/utils-service/utils.service';
import { MatIcon } from '@angular/material/icon';
import { COMPONENT_STATE, ComponentState, GhostStateFragmentDirective } from '@futura/futura-ui/ghost';

interface FileInfo {
  url: string | SafeUrl;
  title: string;
  image: string;
}

const EMPTY_IMG = 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Ic_attach_file_48px.svg/1200px-Ic_attach_file_48px.svg.png';

@Component({
  selector: 'app-files-download',
  templateUrl: './files-download.component.html',
  styleUrls: ['./files-download.component.scss'],
  standalone: true,
  imports: [MatIcon, GhostStateFragmentDirective],
})
export class FilesDownloadComponent implements OnChanges, OnDestroy {
  public state: ComponentState = COMPONENT_STATE.LOADING;

  @Input() public fileUrls?: Array<string>;
  @Input() public type?: SourceType;
  public filesInfo: Array<FileInfo> = [];
  private subscription?: Subscription;

  constructor(
    private downloadService: DownloadService,
    private sanitizer: DomSanitizer,
    private utilsService: UtilsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentFileUrls = changes.fileUrls?.currentValue;
    if (currentFileUrls) {
      this.onFilesChanged(currentFileUrls);
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public trackByEl(index: number, el: FileInfo): string {
    return el.image;
  }

  public download(file: FileInfo): void {
    this.utilsService
      .sendEvent('action_video_attachment_download', {
        title: file.title,
        attachment_url: file.url,
        type: this.type,
      })
      .catch(console.error);
  }

  public imgError(info: FileInfo): void {
    info.image = EMPTY_IMG;
  }

  private onFilesChanged(fileUrls: Array<string>): void {
    this.state = COMPONENT_STATE.LOADING;
    this.filesInfo = new Array(fileUrls.length).fill({});

    const files$ = fileUrls.map(file =>
      this.downloadService.getLinkInfo(file).pipe(
        timeout(2_000),
        catchError(() =>
          of({
            title: file,
            image: EMPTY_IMG,
          })
        ),
        map(info => ({ ...info, file }))
      )
    );

    this.subscription = forkJoin(files$).subscribe({
      next: results => {
        console.log({ results });
        const res = results
          .filter(info => !!info.file && !!info.file.trim())
          .map(info => ({
            url: this.sanitizer.bypassSecurityTrustUrl(info.file),
            title: info.title,
            image: info.image,
          }));

        this.filesInfo = res.filter(r => !!r.url);
        this.state = COMPONENT_STATE.NONE;
      },
      error: () => (this.state = COMPONENT_STATE.GHOST),
    });
  }
}
