import { environment } from "./environment";

const _ENVIRONMENT = {
  local: {
    // "lambdas_url": "http://localhost:3000",
    lambdas_url:
      "https://ro2zughedf.execute-api.eu-central-1.amazonaws.com/Prod",
    segment_key: "Ei1FkdZQ3zcqr0iBs48ssWA4u2VcmAX2",
    zoom_key: "Ma4YXMBYSRqHBrIjdErteA",
    usersnap_key: "8b382b09-f955-4a56-b81b-3b513fd10bbc",
    alice_api: "http://localhost:6900"
  },
  stage: {
    lambdas_url:
      "https://ro2zughedf.execute-api.eu-central-1.amazonaws.com/Prod",
    segment_key: "Ei1FkdZQ3zcqr0iBs48ssWA4u2VcmAX2",
    zoom_key: "Ma4YXMBYSRqHBrIjdErteA",
    usersnap_key: "8b382b09-f955-4a56-b81b-3b513fd10bbc",
    alice_api: "https://alice-dev-fcbe75907ab6.herokuapp.com"
  },
  test: {
    lambdas_url:
      "https://ro2zughedf.execute-api.eu-central-1.amazonaws.com/Prod",
    segment_key: "Ei1FkdZQ3zcqr0iBs48ssWA4u2VcmAX2",
    zoom_key: "Ma4YXMBYSRqHBrIjdErteA",
    usersnap_key: "8b382b09-f955-4a56-b81b-3b513fd10bbc",
    alice_api: "https://alice-prod-a0916b49bc93.herokuapp.com"
  },
  prod: {
    lambdas_url:
      "https://avderpijgd.execute-api.eu-central-1.amazonaws.com/Prod",
    segment_key: "Ei1FkdZQ3zcqr0iBs48ssWA4u2VcmAX2",
    zoom_key: "Ma4YXMBYSRqHBrIjdErteA",
    usersnap_key: "8b382b09-f955-4a56-b81b-3b513fd10bbc",
    alice_api: "https://alice-prod-a0916b49bc93.herokuapp.com"
  },
};

export const gEnvironment =
  _ENVIRONMENT[
    environment.environment_type as "local" | "stage" | "test" | "prod"
  ];
